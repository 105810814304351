import { useEffect, useRef, useState } from 'react';
//styles
import { Modal, Button, useMantineTheme, Image, createStyles, Stack } from '@mantine/core';
import { Database, Plus, RotateClockwise } from 'tabler-icons-react';
import { showNotification } from '@mantine/notifications';
import Logo from '../res/logo.png'



export function WelcomeModal() {
  const [opened, setOpened] = useState(false);
  const fileRef = useRef<HTMLInputElement>();
  const image = <Image id='logo_Welcome' src={Logo} />
  const useStyles = createStyles(() => ({
    padded: {
      padding: '4px'
    }
  }));


  const loadFromDisk = async (e: any) => {

    let resultText = await e.target.files.item(0).text();

    // if (resultText) {
    //   let action = new LoadAction(resultText);
    //   action.execute();
    //   setOpened(false)
    // }

  };

  const theme = useMantineTheme();
  const { classes } = useStyles();
  useEffect(() => {
    setOpened(true);
  }, [])

  const notification = {
    title: "Добро Пожаловать!",
    message: '⚒️Используйте инструменты в меню слева, чтобы редактировать ваш план. Для получения подробной инструкции нажмите кнопку "Помощь" в меню слева.',
    color: "orange",
    
  }

  return (
    <>
      <Modal className={classes.padded}
        closeOnClickOutside={false}
        closeOnEscape={false}
        opened={opened}
        withCloseButton={false}
        overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        centered
        onClose={() => {
          setOpened(false)
          // showNotification(notification)
        }}

      >
        <Stack spacing="xs">
          {image}
          <Button  id='newProjectBut' onClick={() => {
            setOpened(false); showNotification(notification)
          }} leftIcon={<Plus id='welcome__modal_plus_icon'/>} variant="white">
            Новый План
          </Button>
          <input
            ref={fileRef}
            onChange={loadFromDisk}
            multiple={false}
            type="file"
            hidden
          />
          <Button onClick={() => {
            fileRef.current.click()
          }} leftIcon={<Database />} variant="white">
            Загрузка с диска
          </Button>
          <Button
            onClick={() => {
              // FloorPlan.Instance.load(localStorage.getItem('autosave'));
              setOpened(false)
            }} leftIcon={<RotateClockwise />} variant="white">
            Загрузить последнее сохранение
          </Button>
        </Stack>
      </Modal>
    </>
  );
}