import { BrowserRouter, Routes, Route } from "react-router-dom";

//app modules
import Header from './Pages/Header/Header';
import MainPage from './Pages/Main/MainPage';
import { PageLayout } from './ui/Layout/PageLayout';
import ContactsPage from './Pages/Contacts/ContactsPage';
//styles
import { NotificationsProvider } from '@mantine/notifications';
import './App.css';

export default function App() {
  return (
    <>
      <div className='appDiv'>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={(
              <><NotificationsProvider style={{ marginBottom: '8vh' }}>
                <PageLayout />
              </NotificationsProvider></>)} />
            <Route path='/main' element={<MainPage />} />
            <Route path='/contacts' element={<ContactsPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}
