import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom"

//styles
import logoHeader from "./logo.png";
import "./Header.css";

export default function Header() {
  const [activeButton, setActiveButton] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === '/') {
      setActiveButton(0);
    } else if (currentPath === '/contacts') {
      setActiveButton(1);
    } else {
      setActiveButton(null);
    }
  }, [location.pathname]);

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  return (
    <>
      <div className="MainHeader">
        <NavLink to={'/'}>
          <img id="logoHeader" src={logoHeader} alt="logo" />
        </NavLink>

        <div className="buttons_header_container">
          <div className=".btn_container">
            <NavLink to={'/'} className={activeButton === 0 ? "active" : ""}>
              <button
                className="ButtonHeader"
                onClick={() => handleButtonClick(0)}
              >
                <p>Планировщик</p>
              </button>
            </NavLink>
          </div>

          <div className=".btn_container">
            <NavLink to={'/contacts'} className={activeButton === 1 ? "active" : ""}>
              <button
                className="ButtonHeader"
                onClick={() => handleButtonClick(1)}
              >
                <p>Контакты</p>
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
