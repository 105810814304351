/**
 * Преобразует угол из радиан в градусы.
 * @param radians - Угол в радианах
 * @returns Угол в градусах
 */
export const radiansToDegrees = (radians: number) => {
    return radians * (180 / Math.PI);
};

/**
 * Преобразует угол из градусов в радианы.
 * @param degrees - Угол в градусах
 * @returns Угол в радианах
 */
export const degreesToRadians = (degrees: number) => {
    return degrees * (Math.PI / 180);
};

/**
 * Нормализует угол в диапазоне [0, 360) градусов.
 * @param angle - Угол в градусах
 * @returns Нормализованный угол в градусах
 */
export const normalizeAngle = (angle: number) => {
    return (angle + 360) % 360;
};