/**
 * Функция для прилипания угла к ближайшему заданному углу.
 * 
 * Эта функция принимает координаты вектора (dy, dx) и массив углов прилипания.
 * Она вычисляет угол вектора и проверяет, находится ли он достаточно близко
 * к одному из заданных углов прилипания. Если да, то возвращает этот угол прилипания.
 * В противном случае возвращает исходный вычисленный угол.
 * 
 * @param dx - Разница по горизонтали (x-координата вектора)
 * @param dy - Разница по вертикали (y-координата вектора)
 * @param snapAngles - Массив углов прилипания в градусах
 * @returns Угол в градусах (прилипший или исходный)
 */

import { normalizeAngle } from "./angleUtils";
import { vectorToAngle } from "./vectorUtils";

export const snapToAngle = (dx: number, dy: number, baseAngle: number): number => {
    const angle = vectorToAngle(dx, dy);
    const relativeAngle = normalizeAngle(angle - baseAngle);
    
    // Углы прилипания: 0°, 90°, 180°, 270° (в радианах)
    const snapAngles = [0, Math.PI/2, Math.PI, 3*Math.PI/2];
    
    // Находим ближайший угол прилипания
    let closestAngle = snapAngles.reduce((prev, curr) => 
        Math.abs(curr - relativeAngle) < Math.abs(prev - relativeAngle) ? curr : prev
    );
    
    // Возвращаем абсолютный угол (относительно глобальной системы координат)
    // return normalizeAngle(closestAngle + baseAngle);
    return normalizeAngle(relativeAngle);

};