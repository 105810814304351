import { useState } from "react";
import { useStore } from "../../../stores/EditorStore";

export const FloorComponent = () => {
//   const { floor, setFloor } = useStore();

    return (
        <div className="forFloorsBox">
            <div className="floorsBox">
                <span className="planner_subobjects_item_current accordion">
                    {/* Этаж {floor} */}
                    <div>
                        <button
                            className="bbut"
                            onClick={() => {
                                // setFloor(floor - 1);
                            }}
                        >
                            <i className="material-icons">arrow_downward</i>
                        </button>
                        <button
                            className="bbut"
                            onClick={() => {
                                // setFloor(floor + 1);
                            }}
                        >
                            <i className="material-icons">arrow_upward</i>
                        </button>
                    </div>
                </span>
                <div className="panel" style={{ display: "none" }}>
                    {" "}
                    <button>Button</button>{" "}
                </div>
            </div>
        </div>
    );
};