import { useCallback, useState } from "react";
import { IPosition, ISize, SnapPointInfo } from "../types/moduleTypes";

interface ToolState {
  isDragging: boolean;
  position: IPosition;
  endPosition: IPosition;
  size: ISize;
  rotation: number;
  lineaHint: boolean;
  snapPoint: SnapPointInfo | null;
  nearestWall: { start: IPosition, end: IPosition } | null;
}

interface ToolStateActions {
  setIsDragging: (isDragging: boolean) => void;
  setPosition: (position: IPosition) => void;
  setEndPosition: React.Dispatch<React.SetStateAction<IPosition>>;
  setSize: (size: ISize | ((prevSize: ISize) => ISize)) => void;
  setRotation: (rotation: number) => void;
  setLineaHint: (lineaHint: boolean) => void;
  resetToDefault: () => void;
  setSnapPoint: React.Dispatch<React.SetStateAction<SnapPointInfo>>;
  setNearestWall: any;
}

export type UseToolStateReturn = ToolState & ToolStateActions;

export function useToolState(): UseToolStateReturn {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<IPosition>({ x: -100, y: -100 });
  const [endPosition, setEndPosition] = useState<IPosition>({ x: 0, y: 0 });
  const [size, setSize] = useState<ISize>({ width: 10, height: 10 });
  const [rotation, setRotation] = useState<number>(0);
  const [lineaHint, setLineaHint] = useState<boolean>(false);
  const [snapPoint, setSnapPoint] = useState<SnapPointInfo | null>(null);
  const [nearestWall, setNearestWall] = useState<{ start: IPosition, end: IPosition } | null>(null);

  const resetToDefault = useCallback(() => {
    setIsDragging(false);
    setPosition({ x: -100, y: -100 });
    setEndPosition({ x: 0, y: 0 });
    setSize({ width: 10, height: 10 });
    setRotation(0);
    setLineaHint(false);
    setSnapPoint(null);
  }, []);

  return {
    isDragging, setIsDragging,
    position, setPosition,
    endPosition, setEndPosition,
    size, setSize,
    rotation, setRotation,
    lineaHint, setLineaHint,
    snapPoint, setSnapPoint,
    nearestWall, setNearestWall,
    resetToDefault
  };
}