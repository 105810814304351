import { NavLink } from "react-router-dom";
import { Tabs, Tab } from "@mantine/core";
import { useStore } from "../../../stores/EditorStore";
import { Plus } from "tabler-icons-react";

export const ButtonControl = () => {
    // const { tabs, activeTab, setActiveTab, addTab } = useStore();

    return (
        <div className="buttomPanel">
            <div className="leftBottomRoutesPlanner">
                <div className="routerContainerBottomPanel">
                    <NavLink to={"/planner/support"}>
                        <p>Помощь по планировщику</p>
                    </NavLink>
                </div>
                <div className="routerContainerBottomPanel">
                    <p id="ErrorSEndRouteP">Сообщить об ошибке</p>
                </div>
            </div>
            <div className="toolsBottomPanel">
                {/* <Tabs active={activeTab} onTabChange={setActiveTab}>
                {tabs.map((tab) => (
                        <Tab key={tab} label={`${tab}`} />
                    ))}
                </Tabs>
                <Plus size={16} color="black" style={{ marginLeft: "10px"}} onClick={addTab} /> */}
            </div>
        </div>
    );
};