import { useRef, useState } from "react";
import "./EditorRoot.css";
import { Group, Layer, Stage } from "react-konva";
import { PatternModule } from "./modules/patternModule";
import { LeftMenu } from "./components/leftMenu";
import { FloorComponent } from "./components/floorComponent";
import { ZoomComponent } from "./components/zoomComponent";
import { ButtonControl } from "./components/buttonControl";
import Konva from 'konva';
import { CursorWall } from "./modules/cursorWall";
import { CursorWindow } from "./modules/cursorWindow";
import { CursorDoor } from "./modules/cursorDoor";
import { CursorStaircase } from "./modules/cursorStaircase";
import { SizeParameterControl } from "./components/sizeParameterControl";
import { useStore } from "../stores/EditorStore";
import { CursorRuler } from "./modules/cursorRuler";
import { useEngine } from "./hooks/useEngine";
import { useZoom } from "./hooks/useZoom";
import ModuleRenderer from "./components/moduleRenderer";
import { useEditorToolState } from "./hooks/useEditorToolState";
import { MatterBodiesRenderer } from "./MatterBodiesRenderer";
import { GlobalGrid } from "./utils/globalDiagonalGrid";
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { PerimeterModule } from "./modules/perimeterModule";
import { PerimeterModuleTwo } from "./modules/perimeterModuleTwo";


export function EditorRoot() {

  // use global state
  // const { allModules, activeTool, floor, tabs, activeTab, setTool } = useStore();

  // create engine for physics
  const engineRef = useEngine();

  // useRef for layer and Stage
  const stageRef = useRef<Konva.Stage | null>(null);

  // useZoom hook
  const { scale, handleZoomChange, increaseScale, decreaseScale, onStageWheel } = useZoom(stageRef);

  // useEditorToolState hook
  const {
    isDragging,
    position,
    endPosition,
    size,
    rotation,
    lineaHint,
    setSize,
    snapPoint,
    nearestWall,
    handleMouseMove,
    handleMouseUp,
    // handleMouseDown
  } = useEditorToolState({ stageRef, engine: engineRef.current });


  return (
    <div style={{ borderRight: "1px solid black" }}>

      <FloorComponent />

      {/* {["door", "wall", "staircase", "window"].includes(activeTool) &&
        <SizeParameterControl
          tool={activeTool}
          width={size.width}
          height={size.height}
          changeNumWidth={changeNumber}
          onChangeHeight={(e) => setSize((prev) => ({ ...prev, height: Number(e.target.value) }))}
          onChangeWidth={(e) => setSize((prev) => ({ ...prev, width: Number(e.target.value) }))}
        />
      } */}

      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        ref={stageRef}
        draggable={!isDragging}
        onWheel={onStageWheel}
        // onMouseMove={(e) => handleMouseMove(e, activeTool)}
        // onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onContextMenu={(e) => {
          e.evt.preventDefault()
          // setTool(null)
        }}
      >
        <Layer>
          <PatternModule stageRef={stageRef} />
        </Layer>

        <Layer>
          <PerimeterModule
            initialPosition={{ x: 100, y: 100 }}
            initialSize={{ width: 200, height: 150 }}
            wallThickness={10}
            onUpdate={(newProps) => console.log("Perimeter 1 updated:", newProps)}
          />

          <PerimeterModuleTwo
            initialPosition={{ x: 350, y: 100 }}  // Изменено значение x
            initialSize={{ width: 200, height: 150 }}
            wallThickness={10}
            onUpdate={(newProps) => console.log("Perimeter 2 updated:", newProps)}
          />
          {/* <ModuleRenderer />
          {activeTool === 'wall' && <PerimeterModule
            initialPosition={{ x: 100, y: 100 }}
            initialSize={{ width: 200, height: 150 }}
            wallThickness={10}
            onUpdate={(newProps) => {
              // Обработка обновлений периметра
              console.log(newProps);
            }}
          />}
          {activeTool === 'window' && <CursorWindow position={position} size={size} rotation={rotation} />}
          {activeTool === 'door' && <CursorDoor position={position} size={size} rotation={rotation} />}
          {activeTool === 'staircase' && <CursorStaircase position={position} size={size} />}
          {activeTool === 'ruler' && <CursorRuler position={position} endPosition={endPosition} size={size} rotation={rotation} />} */}
        </Layer>

        <MatterBodiesRenderer engine={engineRef.current} />
      </Stage>
      <ZoomComponent scale={scale} increaseScale={increaseScale} decreaseScale={decreaseScale} handleZoomChange={handleZoomChange} />
      <ButtonControl />
      {/* <LeftMenu changeTool={setTool} /> */}
    </div>
  );
};