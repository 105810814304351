import { useEffect, useRef, useState } from "react";
import {
  Navbar,
  createStyles,
} from "@mantine/core";
import { useStore } from "../../../stores/EditorStore";
import { Tool } from "../../../editor/editor/constants";
import { NavbarLink } from "../../NavbarLink";
import { HelpDialog } from "../../HelpDialog";
//import icons
import {
  Icon as TablerIcon,
  DeviceFloppy,
  Upload,
  Printer,
  Ruler2,
  Eye,
  Pencil,
  Trash,
  Eraser,
  ArrowBack,
  FileDownload,
  Icon
} from "tabler-icons-react";

import "./ToolNavBar.css";
import { ToolType } from "../../../editor/types/toolType";


const useStyles = createStyles((theme) => ({
  link: {
    width: 100,
    height: 40,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],


    active: {
      "&, &:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.fn.rgba(theme.colors["orange"][9], 0.25)
            : theme.colors["orange"][0],
        color: theme.colors["orange"][theme.colorScheme === "dark" ? 4 : 7],
      },
    }
  }
}));





export function ToolNavbar() {
  const [active, setActive] = useState(-1);
  // const { activeTool, setTool, history, undo, setHistory, setAllModules } = useStore();

  const fileRef = useRef<HTMLInputElement>();

  type ModesType = {
    icon: Icon;
    label: string;
    tool: ToolType;
  }

  // useEffect(() => {
  //   setActive(-1)
  // }, [activeTool])

  const modes: ModesType[] = [
    { icon: Ruler2, label: "Линейка", tool: 'ruler' },
    // { icon: Eye, label: "Просмотр", tool: Tool.View },
    // { icon: Pencil, label: "Правка", tool: Tool.Edit },
    { icon: Eraser, label: "Ластик", tool: 'remove' },
  ];

  const tools = modes.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={active === index}
      onClick={() => {
        setActive(index);
        // setTool(link.tool);
      }}
    />
  ));


  const handleChange = async (e: any) => {
    let resultText = await e.target.files.item(0).text();

  };

  const setterAction = (val) => {
    setActive(val);
  };

  return (
    <>
      <div className="toolbarDiv" >
        <Navbar>
          <Navbar.Section id="leftSection">
            {/* <Group> */}
            <div className="Save_group">
              <NavbarLink
                icon={Printer}
                label="Распечатать"
                onClick={() => {
                }}
              />
              <NavbarLink
                icon={DeviceFloppy}
                label="Сохранить план"
                onClick={() => {
                }}
              />

              <NavbarLink
                onClick={() => fileRef.current.click()}
                icon={Upload}
                label="Загрузить план"
              />

              <input
                ref={fileRef}
                onChange={handleChange}
                multiple={false}
                type="file"
                hidden
              />

              <NavbarLink
                icon={FileDownload}
                label="Загрузить изображение"
              />

              <div id="leftMarginDiv">
                {tools}
                <NavbarLink
                  icon={ArrowBack}
                  label="Отменить последнее действие"
                  key={'back1do'}
                  // onClick={undo}
                />
              </div>
              <NavbarLink
                icon={Trash}
                label="Удалить Чертеж"
                key={'resetPlan'}
                onClick={() => {
                  setActive(5);
                  // setTool('removeAll');
                  window.location.reload();
                }}
              />
              <HelpDialog />
            </div>

            {/* <div className="floor_group">
              <NavbarLink
              icon={StairsUp}
              label="Перейти на следующий этаж"
              onClick={() => {
                let action = new ChangeFloorAction(1);
                action.execute();
              }}
            />
             <Tooltip
              label={"Текущий этаж"}
              position="bottom"
              withArrow
              transitionDuration={0}
            >
              <div className={classes.link}>{floor}</div>
            </Tooltip>
            <NavbarLink
              icon={StairsDown}
              label="Перейти на предыдущий этаж"
              onClick={() => {
                let action = new ChangeFloorAction(-1);
                action.execute();
              }}
            />
            <NavbarLink
              icon={SquareX}
              label="Удалить этаж"
              onClick={() => {
                let action = new DeleteFloorAction();
                action.execute();
              }}
            />
              </div> */}


            {/* <NavbarLink
              icon={ArrowBottomSquare}
              label="Привязки к сетке"
              onClick={() => {
                setSnap(!snap);
                cleanNotifications();
                showNotification({
                  message: "Привязать к сетке сейчас " + (snap ? "Выкл" : "Вкл"),
                  color: "orange",
                  icon: snap ? <Table /> : <TableOff />,
                });
              }}
            /> */}

            {/* <NavbarLink
              icon={Dimensions}
              label="Переключить метки размеров"
              onClick={() => {
                let action = new ToggleLabelAction();
                action.execute();
                cleanNotifications();
                showNotification({
                  message: "Переключить метки размеров",
                  icon: <Tag />,
                  color: "orange",
                });
              }}
            /> */}
          </Navbar.Section>
        </Navbar>

        {/* <SliderSlick/> */}
      </div>


    </>
  );
}
