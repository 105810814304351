import Konva from "konva";
import { MutableRefObject, useCallback, useState } from "react";

interface UseZoomReturn {
    scale: number;
    handleZoomChange: (value: string) => void;
    increaseScale: () => void;
    decreaseScale: () => void;
    onStageWheel: (e: Konva.KonvaEventObject<WheelEvent>) => void;
}



// ZOOM HOOK
export const useZoom = (stageRef: MutableRefObject<Konva.Stage | null>): UseZoomReturn => {
    const [scale, setScale] = useState(4);

    const updateScale = useCallback((newScale: number) => {
        setScale(newScale);
        if (stageRef.current) {
            stageRef.current.scale({ x: newScale, y: newScale });
            stageRef.current.draw();
        }
    }, [stageRef]);

    const handleZoomChange = useCallback((value: string) => {
        const newScale = parseFloat(value);
        if (!isNaN(newScale)) {
            updateScale(newScale);
        }
    }, [updateScale]);

    const increaseScale = useCallback(() => {
        const newScale = Math.min(Math.max(scale * 1.05, 0.2), 8);
        updateScale(newScale);
    }, [scale, updateScale]);

    const decreaseScale = useCallback(() => {
        const newScale = Math.min(Math.max(scale * 0.95, 0.2), 8);
        updateScale(newScale);
    }, [scale, updateScale]);


    const onStageWheel = useCallback((e: Konva.KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();

        const stage = stageRef.current;
        if (!stage) return;

        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        if (!pointer) return;

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const delta = e.evt.deltaY < 0 ? 1.1 : 0.9;
        const newScale = Math.min(Math.max(oldScale * delta, 0.2), 8);

        stage.scale({ x: newScale, y: newScale });

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        stage.position(newPos);
        stage.batchDraw();

        setScale(newScale);
    }, [stageRef]);

    return { scale, handleZoomChange, increaseScale, decreaseScale, onStageWheel };
};