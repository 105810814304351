import Matter from "matter-js";
import { degreesToRadians } from "./angleUtils";

/**
 * Поворачивает вектор на заданный угол.
 * @param dx - X-компонента вектора
 * @param dy - Y-компонента вектора
 * @param angle - Угол поворота в радианах
 * @returns Объект с x и y компонентами повернутого вектора
 */
export const rotateVector = (dx: number, dy: number, angle: number) => {
    const vector = Matter.Vector.create(dx, dy);
    return Matter.Vector.rotate(vector, angle);
};

/**
 * Вычисляет длину вектора.
 * @param dx - X-компонента вектора
 * @param dy - Y-компонента вектора
 * @returns Длина вектора
 */
export const vectorLength = (dx: number, dy: number) => {
    return Matter.Vector.magnitude(Matter.Vector.create(dx, dy));
};

/**
 * Вычисляет угол вектора относительно положительного направления оси X.
 * @param dx - X-компонента вектора
 * @param dy - Y-компонента вектора
 * @returns Угол в радианах
 */
export const vectorToAngle = (dx: number, dy: number) => {
    return Math.atan2(dy, dx);
};

/**
 * Создает вектор заданной длины по углу.
 * @param angle - Угол в радианах
 * @param length - Длина вектора (по умолчанию 1)
 * @returns Объект с x и y компонентами вектора
 */
export const angleToVector = (angle: number, length: number = 1) => {
    const radians = degreesToRadians(angle);
    const vector = Matter.Vector.create(Math.cos(radians), Math.sin(radians));
    return Matter.Vector.mult(vector, length);
};

/**
 * Создает вектор, перпендикулярный заданному углу.
 * @param angle - Угол в радианах
 * @param length - Длина вектора (по умолчанию 1)
 * @returns Объект с x и y компонентами перпендикулярного вектора
 */
export const perpendicularVector = (angle: number, length: number = 1) => {
    const vector = angleToVector(angle);
    const perpVector = Matter.Vector.perp(vector);
    return Matter.Vector.mult(perpVector, length);
};