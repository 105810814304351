//styles
import './MainPage.css';

export default function MainPage() {
    return(<>
    <div className="MainPageContainer">
        <h1 style={{color:'black'}}>
        Звесь Будет главная страница
            </h1>
            <h2 style={{color:'black'}}>Пока вы можете перейти в planner или contacts</h2>
        
    </div>
    </>)
}