import { EditorRoot } from '../../editor/EditorRoot';
import { WelcomeModal } from '../WelcomeModal';
import { ToolNavbar } from './ToolNavBar/ToolNavbar';



export function PageLayout() {
    return (<>

        <WelcomeModal />
        <ToolNavbar />
        <EditorRoot />
    </>
    );
}
