import Konva from "konva";
import React, { useCallback, useEffect, useState } from "react";
import { Group, Line } from "react-konva";

interface PatternModuleProps {
    stageRef: React.RefObject<Konva.Stage>;
}

export const PatternModule: React.FC<PatternModuleProps> = React.memo(({ stageRef }) => {
    const [lines, setLines] = useState<JSX.Element[]>([]);

    const updatePattern = useCallback(() => {
        if (!stageRef.current) return;

        const stage = stageRef.current;
        const stageWidth = stage.width();
        const stageHeight = stage.height();
        const scale = stage.scaleX();

        const patternWidth = stageWidth / scale * 3;
        const patternHeight = stageHeight / scale * 3;

        const stagePos = stage.position();
        const offsetX = -stagePos.x / scale;
        const offsetY = -stagePos.y / scale;
        
        const gridSize = 10;
        const startX = Math.floor(offsetX / gridSize) * gridSize;
        const startY = Math.floor(offsetY / gridSize) * gridSize;
        const endX = startX + patternWidth;
        const endY = startY + patternHeight;

        const newLines: JSX.Element[] = [];

        for (let x = startX; x <= endX; x += gridSize) {
            const isMajorLine = x % 100 === 0 ;
            const strokeWidth = isMajorLine ? 0.23 : 0.15;
            const strokeColor = isMajorLine ? "black" : "gray";
            newLines.push(
                <Line
                    key={`v${x}`}
                    points={[x, startY, x, endY]}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                />
            );
        }

        for (let y = startY; y <= endY; y += gridSize) {
            const isMajorLine = y % 100 === 0;
            const strokeWidth = isMajorLine ? 0.23 : 0.15;
            const strokeColor = isMajorLine ? "black" : "gray";
            newLines.push(
                <Line
                    key={`h${y}`}
                    points={[startX, y, endX, y]}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                />
            );
        }

        setLines(newLines);
    }, [stageRef]);

    useEffect(() => {
        updatePattern();

        const stage = stageRef.current;
        if (stage) {
            stage.on('scaleChange', updatePattern);
            stage.on('dragmove', updatePattern);
        }

        return () => {
            if (stage) {
                stage.off('scaleChange', updatePattern);
                stage.off('dragmove', updatePattern);
            }
        };
    }, [stageRef, updatePattern]);

    return <Group>{lines}</Group>;
});