import create from 'zustand'
import { ToolType } from '../editor/types/toolType';
import { AllModuleType } from '../editor/types/moduleTypes';

export type EditorMode = 'View' | 'Edit'

interface Floor {
  modules: AllModuleType[];
  history: AllModuleType[][];
  historyStep: number;
}

export interface EditorStore {
  // Глобальные настройки
  mode: EditorMode;
  activeTool: ToolType | null;
  activeFloor: number;

  // Этажи
  floors: { [floorNumber: number]: Floor };

  // Методы для управления глобальными настройками
  setMode: (mode: EditorMode) => void;
  setTool: (tool: ToolType | null) => void;
  setActiveFloor: (floor: number) => void;

  // Методы для управления этажами и модулями
  addFloor: () => void;
  addModule: (newModule: AllModuleType) => void;
  updateModule: (id: string, updates: Partial<AllModuleType>) => void;
  removeModule: (id: string) => void;

  // Методы для управления историей
  undo: () => void;
  redo: () => void;
}

export const useStore = create<EditorStore>((set, get) => ({
  mode: 'View' as EditorMode,
  activeTool: null,
  activeFloor: 1,
  floors: { 1: { modules: [], history: [[]], historyStep: 0 } },

  setMode: (mode) => set({ mode }),
  setTool: (tool) => set({ activeTool: tool }),
  setActiveFloor: (floor) => set({ activeFloor: floor }),

  addFloor: () => set((state) => {
    const newFloorNumber = Math.max(...Object.keys(state.floors).map(Number)) + 1;
    return {
      floors: {
        ...state.floors,
        [newFloorNumber]: { modules: [], history: [[]], historyStep: 0 }
      }
    };
  }),

  addModule: (newModule: AllModuleType) => set((state) => {
    const floorNumber = state.activeFloor;
    const floor = state.floors[floorNumber];
    const newModules = [...floor.modules, newModule];
    const newHistory = [...floor.history.slice(0, floor.historyStep + 1), newModules];
    return {
      floors: {
        ...state.floors,
        [floorNumber]: {
          ...floor,
          modules: newModules,
          history: newHistory,
          historyStep: newHistory.length - 1
        }
      }
    };
  }),

  updateModule: (id: string, updates: Partial<AllModuleType>) => set((state) => {
    const floorNumber = state.activeFloor;
    const floor = state.floors[floorNumber];
    const newModules = floor.modules.map(module =>
      module.id === id ? { ...module, ...updates } : module
    );
    const newHistory = [...floor.history.slice(0, floor.historyStep + 1), newModules];
    return {
      floors: {
        ...state.floors,
        [floorNumber]: {
          ...floor,
          modules: newModules,
          history: newHistory,
          historyStep: newHistory.length - 1
        }
      }
    };
  }),

  removeModule: (id: string) => set((state) => {
    const floorNumber = state.activeFloor;
    const floor = state.floors[floorNumber];
    const newModules = floor.modules.filter(module => module.id !== id);
    const newHistory = [...floor.history.slice(0, floor.historyStep + 1), newModules];
    return {
      floors: {
        ...state.floors,
        [floorNumber]: {
          ...floor,
          modules: newModules,
          history: newHistory,
          historyStep: newHistory.length - 1
        }
      }
    };
  }),

  undo: () => set((state) => {
    const floorNumber = state.activeFloor;
    const floor = state.floors[floorNumber];
    if (floor.historyStep > 0) {
      return {
        floors: {
          ...state.floors,
          [floorNumber]: {
            ...floor,
            modules: floor.history[floor.historyStep - 1],
            historyStep: floor.historyStep - 1
          }
        }
      };
    }
    return state;
  }),

  redo: () => set((state) => {
    const floorNumber = state.activeFloor;
    const floor = state.floors[floorNumber];
    if (floor.historyStep < floor.history.length - 1) {
      return {
        floors: {
          ...state.floors,
          [floorNumber]: {
            ...floor,
            modules: floor.history[floor.historyStep + 1],
            historyStep: floor.historyStep + 1
          }
        }
      };
    }
    return state;
  })
}));