import React, { useEffect, useState } from 'react';
import { Layer, Line } from 'react-konva';
import Matter from 'matter-js';

interface MatterBodiesRendererProps {
  engine: Matter.Engine;
}

export const MatterBodiesRenderer: React.FC<MatterBodiesRendererProps> = ({ engine }) => {
  const [bodies, setBodies] = useState<Matter.Body[]>([]);

  useEffect(() => {
    if (!engine) return;
    const updateBodies = () => {
      setBodies(Matter.Composite.allBodies(engine.world));
    };

    Matter.Events.on(engine, 'afterUpdate', updateBodies);

    return () => {
      Matter.Events.off(engine, 'afterUpdate', updateBodies);
    };
  }, [engine]);

   if (!engine) return null;
  return (
    <Layer>
      {bodies.map((body) => {
        const { vertices } = body;
        const points = vertices.flatMap(v => [v.x, v.y]);
        return (
          <Line
            key={body.id}
            points={points}
            closed
            fill="rgba(255, 0, 0, 0.2)"
            stroke="red"
            strokeWidth={1}
          />
        );
      })}
    </Layer>
  );
};