import { useMemo } from "react";

export const ZoomComponent = ({ decreaseScale, scale, handleZoomChange, increaseScale }) => {
    const MIN_SCALE = 0.2;
    const MAX_SCALE = 8;
    const STEPS = 10; // 10 шагов для 100%

    const scaleToPercentage = useMemo(() => {
        return Math.round(((scale - MIN_SCALE) / (MAX_SCALE - MIN_SCALE)) * 100);
    }, [scale]);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const percentage = parseInt(e.target.value, 10);
        const newScale = MIN_SCALE + (percentage / 100) * (MAX_SCALE - MIN_SCALE);
        handleZoomChange(newScale.toFixed(2));
    };
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2.5vh",
                position: "absolute",
                bottom: "83.25vh",
                right: "10px",
                zIndex: 3,
            }}
        >
            <button onClick={decreaseScale} className="button">
                -
            </button>
            <input
                className="zoom-control"
                type="range"
                min={0}
                max={100}
                step={100 / STEPS}
                value={scaleToPercentage}
                onChange={handleSliderChange}
            />

            <button onClick={increaseScale} className="button">
                +
            </button>
        </div>
    );
};