import { useStore } from "../../stores/EditorStore";
import Konva from "konva";
import { useToolState } from "./useToolState";
import { useToolMovement } from "./useToolMovement";
import { AllModuleType, ModuleType } from "../types/moduleTypes";
import { useCallback } from "react";
import Matter from "matter-js";


type EditorToolStateProps = {
  stageRef: React.MutableRefObject<Konva.Stage | null>;
  engine: Matter.Engine;
}

export function useEditorToolState({ stageRef, engine }: EditorToolStateProps) {
  // const { activeTool, allModules, setAllModules, addModule, floor, history, setHistory } = useStore();
  const toolState = useToolState();
  const { handleMouseMove } = useToolMovement(toolState, stageRef, engine);


  // Создание нового модуля
  const createNewModule = (type: ModuleType, matterBody: Matter.Body): AllModuleType => {
    return {
      id: matterBody.id.toString(),
      type,
      x: toolState.position.x,
      y: toolState.position.y,
      width: type === 'staircase' ? 100 : toolState.size.width,
      height: type === 'staircase' ? 100 : toolState.size.height,
      ...(type === 'wall' && { rotation: toolState.rotation }),
      snapPoint: toolState.snapPoint ? [toolState.snapPoint] : []
    };
  }


  const createTemporaryBody = (type: ModuleType) => {
    const matterAngle = toolState.rotation;
    const cos = Math.cos(matterAngle);
    const sin = Math.sin(matterAngle);

    // Смещение только по высоте, так как по ширине центр уже в правильном месте
    const offsetY = toolState.size.height / 2;

    // Вычисляем координаты центра тела с учетом поворота
    const centerX = toolState.position.x - offsetY * sin;
    const centerY = toolState.position.y + offsetY * cos;

    return Matter.Bodies.rectangle(
      centerX,
      centerY,
      toolState.size.width,
      toolState.size.height,
      {
        angle: toolState.rotation,
        label: type
      }
    );
  }

  const checkCollision = useCallback((tempBody: Matter.Body) => {
    const walls = Matter.Composite.allBodies(engine.world).filter(body => body.label === 'wall');
    let collisionInfo = null;

    for (const wall of walls) {
      const collision = Matter.Collision.collides(tempBody, wall);
      if (collision) {
        collisionInfo = {
          collidedWith: wall.label,
          collisionPoint: collision.supports[0]
        };
        break;
      }
    }

    return collisionInfo;
  }, [engine]);

  const handleMouseUp = () => {
    toolState.setIsDragging(false);
    toolState.setLineaHint(false);

    // if (['wall', 'window', 'staircase', 'door'].includes(activeTool) && toolState.size.height > 10) {
      // const tempBody = createTemporaryBody(activeTool as ModuleType);
      // if (activeTool === 'wall') {
      //   const collisionInfo = checkCollision(tempBody);
      //   if (collisionInfo) {
      //     toolState.resetToDefault();
      //     return;
      //   }
      // }

    //   const newModule = createNewModule(activeTool as ModuleType, tempBody);
    //   if (toolState.snapPoint) {
    //     const updatedModules = allModules[floor] && allModules[floor].map(module => {
    //       if (module.id.toString() === toolState.snapPoint?.wall.toString()) {
    //         const updatedSnapPoints = [...module.snapPoint, toolState.snapPoint];
    //         console.log(updatedSnapPoints)
    //         return {
    //           ...module,
    //           snapPoint: updatedSnapPoints
    //         };
    //       }
    //       return module;
    //     });

    //     setAllModules([...updatedModules, newModule]);
    //   } else {
    //     addModule(newModule);
    //   }

    //   Matter.Body.setStatic(tempBody, true);
    //   Matter.World.add(engine.world, tempBody);
    // }

    toolState.resetToDefault();

  //   if (activeTool === 'ruler') toolState.setEndPosition({ x: 0, y: 0 });
  }

  // const handleMouseDown = () => {
  //   if (activeTool === 'wall') { toolState.setLineaHint(true); toolState.setIsDragging(true); };
  //   if (activeTool === 'ruler') { toolState.setSize({ width: 1, height: 0 }); toolState.setIsDragging(true); }
  // }

  return {
    ...toolState,
    stageRef,
    handleMouseMove,
    handleMouseUp,
    // handleMouseDown
  };
}