import React, { useCallback } from "react";
import { Group, Shape, Text, Circle, Line, Rect } from "react-konva";
import Konva from "konva";
import { WallCalculationText } from "../utils/wallCalculationText";

interface PerimeterProps {
    initialPosition: { x: number; y: number };
    initialSize: { width: number; height: number };
    wallThickness: number;
    onUpdate?: (newProps: Partial<PerimeterProps>) => void;
}

export const PerimeterModule: React.FC<PerimeterProps> = ({
    initialPosition,
    initialSize,
    wallThickness,
    onUpdate,
}) => {
    const drawPerimeter = useCallback((context: Konva.Context, shape: Konva.Shape) => {
        const { width, height } = initialSize;

        context.beginPath();
        // Внешний контур
        context.moveTo(0, 0);
        context.lineTo(width, 0);
        context.lineTo(width, height);
        context.lineTo(0, height);
        context.closePath();

        // Внутренний контур
        context.moveTo(wallThickness, wallThickness);
        context.lineTo(width - wallThickness, wallThickness);
        context.lineTo(width - wallThickness, height - wallThickness);
        context.lineTo(wallThickness, height - wallThickness);
        context.closePath();


        context.fillStrokeShape(shape);
    }, [initialSize, wallThickness]);


    const drawDimensionLines = useCallback((context: Konva.Context, shape: Konva.Shape) => {
        const { width, height } = initialSize;

        context.beginPath();
        // Верхние линии
        context.moveTo(-15, 0);
        context.lineTo(width + 15, 0);
        context.moveTo(0, -10);
        context.lineTo(width, -10);
        // Правые линии
        context.moveTo(width, -15);
        context.lineTo(width, height + 15);
        context.moveTo(width + 10, 0);
        context.lineTo(width + 10, height);
        // Нижние линии
        context.moveTo(-15, height);
        context.lineTo(width + 15, height);
        context.moveTo(0, height + 10);
        context.lineTo(width, height + 10);
        // Левые линии
        context.moveTo(0, -15);
        context.lineTo(0, height + 15);
        context.moveTo(-10, 0);
        context.lineTo(-10, height);

        context.fillStrokeShape(shape);
    }, [initialSize]);

    const circleRadius = 6;
    const innerWidth = initialSize.width - 2 * wallThickness;
    const innerHeight = initialSize.height - 2 * wallThickness;
    const area = ((innerWidth * innerHeight) / 10000).toFixed(2); // площадь в м²
    const perimeter = Math.round(2 * (innerWidth + innerHeight) / 10); // периметр в см


    return (
        <>
            <Group x={initialPosition.x} y={initialPosition.y}>
                <Rect
                    x={wallThickness}
                    y={wallThickness}
                    width={innerWidth}
                    height={innerHeight}
                    fill="white"
                />

                <Shape
                    sceneFunc={drawPerimeter}
                    stroke="black"
                    strokeWidth={1.5}
                />

                <Shape
                    sceneFunc={drawDimensionLines}
                    stroke="black"
                    strokeWidth={0.5}
                />


                {/* Верхняя сторона */}
                <Text
                    text={`${initialSize.width}`}
                    x={0}
                    y={-14}
                    width={initialSize.width}
                    height={10}
                    fontSize={10}
                    fill="black"
                    align="center"
                    verticalAlign="middle"
                />
                {/* Правая сторона */}
                <Text
                    text={`${initialSize.height}`}
                    x={initialSize.width + 6}
                    y={initialSize.height / 2}
                    fontSize={10}
                    fill="black"
                    rotation={-90}
                    align="center"
                    verticalAlign="middle"
                />
                {/* Нижняя сторона */}
                <Text
                    text={`${initialSize.width}`}
                    x={0}
                    y={initialSize.height + 6}
                    fontSize={10}
                    width={initialSize.width}
                    fill="black"
                    align="center"
                    verticalAlign="middle"
                />
                {/* Левая сторона */}
                <Text
                    text={`${initialSize.height}`}
                    x={-14}
                    y={initialSize.height}
                    height={10}
                    width={initialSize.height}
                    fontSize={10}
                    rotation={-90}
                    fill="black"
                    align="center"
                    verticalAlign="middle"
                />


                {/* Информация о площади и периметре */}
                <Group>

                    <Shape
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(wallThickness + innerWidth / 2 - 30, wallThickness + innerHeight / 2 - 25);
                            context.lineTo(wallThickness + innerWidth / 2 + 30, wallThickness + innerHeight / 2 - 25);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 + 35, wallThickness + innerHeight / 2 - 25, wallThickness + innerWidth / 2 + 35, wallThickness + innerHeight / 2 - 20);
                            context.lineTo(wallThickness + innerWidth / 2 + 35, wallThickness + innerHeight / 2 - 5);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 + 35, wallThickness + innerHeight / 2, wallThickness + innerWidth / 2 + 30, wallThickness + innerHeight / 2);
                            context.lineTo(wallThickness + innerWidth / 2 - 30, wallThickness + innerHeight / 2);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 - 35, wallThickness + innerHeight / 2, wallThickness + innerWidth / 2 - 35, wallThickness + innerHeight / 2 - 5);
                            context.lineTo(wallThickness + innerWidth / 2 - 35, wallThickness + innerHeight / 2 - 20);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 - 35, wallThickness + innerHeight / 2 - 25, wallThickness + innerWidth / 2 - 30, wallThickness + innerHeight / 2 - 25);
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        stroke="black"
                        strokeWidth={0.5}
                        fill="white"
                    />

                    {/* Периметр */}
                    <Text
                        text={`H=${perimeter}`}
                        x={wallThickness + innerWidth / 2 - 35}
                        y={wallThickness + innerHeight / 2 - 25}
                        width={70}
                        height={25}
                        fontSize={14}
                        fontStyle="bold"
                        fill="blue"
                        align="center"
                        verticalAlign="middle"
                    />

                    <Shape
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(wallThickness + innerWidth / 2 - 45, wallThickness + innerHeight / 2 + 20);
                            context.lineTo(wallThickness + innerWidth / 2 + 45, wallThickness + innerHeight / 2 + 20);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 + 50, wallThickness + innerHeight / 2 + 20, wallThickness + innerWidth / 2 + 50, wallThickness + innerHeight / 2 + 25);
                            context.lineTo(wallThickness + innerWidth / 2 + 50, wallThickness + innerHeight / 2 + 40);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 + 50, wallThickness + innerHeight / 2 + 45, wallThickness + innerWidth / 2 + 45, wallThickness + innerHeight / 2 + 45);
                            context.lineTo(wallThickness + innerWidth / 2 - 45, wallThickness + innerHeight / 2 + 45);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 - 50, wallThickness + innerHeight / 2 + 45, wallThickness + innerWidth / 2 - 50, wallThickness + innerHeight / 2 + 40);
                            context.lineTo(wallThickness + innerWidth / 2 - 50, wallThickness + innerHeight / 2 + 25);
                            context.quadraticCurveTo(wallThickness + innerWidth / 2 - 50, wallThickness + innerHeight / 2 + 20, wallThickness + innerWidth / 2 - 45, wallThickness + innerHeight / 2 + 20);
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        stroke="black"
                        strokeWidth={0.5}
                        fill="white"
                    />

                    {/* Площадь */}
                    <Text
                        text={`S=${area} м²`}
                        x={wallThickness + innerWidth / 2 - 50}
                        y={wallThickness + innerHeight / 2 + 20}
                        width={100}
                        height={25}
                        fontSize={14}
                        fontStyle="bold"
                        fill="red"
                        align="center"
                        verticalAlign="middle"
                    />

                </Group>



                {/* Круги */}
                <Circle
                    x={initialSize.width / 2}
                    y={wallThickness / 2}
                    radius={circleRadius}
                    fill="white"
                    stroke="red"
                    strokeWidth={2}
                />

                <Circle
                    x={initialSize.width - wallThickness / 2}
                    y={initialSize.height / 2}
                    radius={circleRadius}
                    fill="white"
                    stroke="red"
                    strokeWidth={2}
                />
                {/* Нижний круг */}
                <Circle
                    x={initialSize.width / 2}
                    y={initialSize.height - wallThickness / 2}
                    radius={circleRadius}
                    fill="white"
                    stroke="red"
                    strokeWidth={2}
                />
                {/* Левый круг */}
                <Circle
                    x={wallThickness / 2}
                    y={initialSize.height / 2}
                    radius={circleRadius}
                    fill="white"
                    stroke="red"
                    strokeWidth={2}
                />
            </Group>
        </>
    );
};