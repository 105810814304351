import { KonvaEventObject } from "konva/lib/Node";
import { ToolType } from "../types/toolType";
import { IPosition } from "../types/moduleTypes";
import Konva from "konva";
import Matter from "matter-js";
import { UseToolStateReturn } from "./useToolState";
import { snapToAngle } from "../utils/snapToAngle";
import { distanceBetweenPoints, findIntersectionWithWalls, lineIntersection } from "../utils/geometryUtils";
import { perpendicularVector, vectorLength, vectorToAngle } from "../utils/vectorUtils";

export function useToolMovement(toolState: UseToolStateReturn, stageRef: React.RefObject<Konva.Stage>, engine: Matter.Engine) {

    // получение позиции курсора по Stage
    const getPointerPosition = (): IPosition => {
        return stageRef.current!.getRelativePointerPosition();
    }

    // Обновление позиции курсора по Stage
    const updatePosition = () => {
        if (!toolState.isDragging) {
            const position = getPointerPosition();
            const cursorWall = Matter.Composite.allBodies(engine.world).find(body => body.label === 'cursorWall');

            if (cursorWall) {
                const walls = Matter.Composite.allBodies(engine.world).filter(body => body.label === 'wall');
                let closestEdge = null;
                let minDistance = Infinity;

                walls.forEach(wall => {
                    const vertices = wall.vertices;
                    for (let i = 0; i < vertices.length; i++) {
                        const start = vertices[i];
                        const end = vertices[(i + 1) % vertices.length];
                        const projection = projectPointOnLine(position, start, end);
                        const distance = Matter.Vector.magnitude(Matter.Vector.sub(projection, position));

                        if (distance < minDistance && distance < 20) {
                            minDistance = distance;
                            closestEdge = { start, end, projection, wall: wall.id };
                        }
                    }
                });

                if (closestEdge) {
                    const dx = closestEdge.end.x - closestEdge.start.x;
                    const dy = closestEdge.end.y - closestEdge.start.y;
                    
                    // Определяем, горизонтальная или вертикальная это стена
                    const angle = vectorToAngle(dx, dy);
                    const length = vectorLength(dx, dy);

                    // Увеличиваем смещение для полного выравнивания по краю
                    const offset = toolState.size.width;
                    const offsetX = Math.cos(angle) * offset;
                    const offsetY = Math.sin(angle) * offset;

                    const alignedPosition = {
                        x: closestEdge.projection.x + offsetY,
                        y: closestEdge.projection.y - offsetX
                    };

                    toolState.setPosition(alignedPosition);
                    toolState.setRotation(angle);

                    toolState.setSnapPoint({
                        point: closestEdge.projection,
                        length: length,
                        rotation: angle,
                        width: toolState.size.width,
                        startPoint: closestEdge.start,
                        endPoint: closestEdge.end,
                        wall: closestEdge.wall
                    });
                } else {
                    toolState.setPosition(position);
                    toolState.setSnapPoint(null);
                }
            } else {
                toolState.setPosition(position);
            }
        }
    }

    const projectPointOnLine = (point: IPosition, lineStart: IPosition, lineEnd: IPosition): IPosition => {
        const dx = lineEnd.x - lineStart.x;
        const dy = lineEnd.y - lineStart.y;
        const t = ((point.x - lineStart.x) * dx + (point.y - lineStart.y) * dy) / (dx * dx + dy * dy);
        const clampedT = Math.max(0, Math.min(1, t));
        return {
            x: lineStart.x + clampedT * dx,
            y: lineStart.y + clampedT * dy
        };
    }


    const calculateDraggedValues = (e: KonvaEventObject<MouseEvent>) => {
        const point = e.target.getStage().getRelativePointerPosition();
        const dx = point.x - toolState.position.x;
        const dy = point.y - toolState.position.y;
        const height = Math.sqrt(dx * dx + dy * dy);
        return { point, dx, dy, height };
    }


    const handleWallMove = (e: KonvaEventObject<MouseEvent>) => {
        if (toolState.isDragging) {
            toolState.setLineaHint(true);

            const point = getPointerPosition();
            const dx = point.x - toolState.position.x;
            const dy = point.y - toolState.position.y;
            const height = vectorLength(dx, dy);

            const snappedAngle = snapToAngle(dx, dy, 0);
            
            const intersection = findIntersectionWithWalls(toolState.position, point, engine);

     
                // Если пересечения нет, используем исходную длину
                toolState.setSize(prev => ({ ...prev, height: Math.floor(height) }));
                toolState.setEndPosition({ x: dx, y: dy });
            

            const snap = toolState.snapPoint;
            if (snap) {
                toolState.setPosition(snap.point)
            }
            toolState.setRotation(snappedAngle - Math.PI / 2);

        } else {
            updatePosition();
        }
    }

    const handleWindowMove = (e: KonvaEventObject<MouseEvent>) => {
        updatePosition();
        if (toolState.isDragging) {
            // тут логика рисования окна
        }
    }

    const handleStaircaseMove = (e: KonvaEventObject<MouseEvent>) => {
        updatePosition();
        if (toolState.isDragging) {
            const { height } = calculateDraggedValues(e);
            toolState.setSize(prev => ({ ...prev, height: Math.floor(height) }));
        }
    }

    const handleRulerMove = (e: KonvaEventObject<MouseEvent>) => {
        updatePosition();
        if (toolState.isDragging) {
            const { height, dx, dy } = calculateDraggedValues(e);
            toolState.setSize(prev => ({ ...prev, height: Math.floor(height) }));
            toolState.setEndPosition({ x: dx, y: dy });
        }
    }

    const handleDoorMove = (e: KonvaEventObject<MouseEvent>) => {
        updatePosition();
        if (toolState.isDragging) {
            // тут логика двери
        }
    }

    const handleMouseMove = (e: KonvaEventObject<MouseEvent>, activeTool: ToolType) => {
        const toolHandlers: Record<ToolType, ((e: KonvaEventObject<MouseEvent>) => void) | undefined> = {
            'wall': handleWallMove,
            'window': handleWindowMove,
            'staircase': handleStaircaseMove,
            'ruler': handleRulerMove,
            'door': handleDoorMove,
            'remove': undefined,
            'removeAll': undefined
        };

        toolHandlers[activeTool]?.(e);
    }

    return { handleMouseMove };
}