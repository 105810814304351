import Matter from "matter-js";
import { IPosition } from "../types/moduleTypes";

/**
 * Находит точку пересечения двух линий, заданных двумя точками каждая.
 * 
 * @param p1 Начальная точка первой линии
 * @param p2 Конечная точка первой линии
 * @param p3 Начальная точка второй линии
 * @param p4 Конечная точка второй линии
 * @returns Точка пересечения или null, если линии не пересекаются или параллельны
 */
export const lineIntersection = (p1: IPosition, p2: IPosition, p3: IPosition, p4: IPosition): IPosition | null => {
    const d = (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);
    if (d === 0) return null;

    const t = ((p1.x - p3.x) * (p3.y - p4.y) - (p1.y - p3.y) * (p3.x - p4.x)) / d;
    const u = -((p1.x - p2.x) * (p1.y - p3.y) - (p1.y - p2.y) * (p1.x - p3.x)) / d;

    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
        return {
            x: p1.x + t * (p2.x - p1.x),
            y: p1.y + t * (p2.y - p1.y)
        };
    }

    return null;
};

/**
 * Проверяет, лежит ли точка на отрезке.
 * 
 * @param point Проверяемая точка
 * @param lineStart Начало отрезка
 * @param lineEnd Конец отрезка
 * @returns true, если точка лежит на отрезке, иначе false
 */
export const isPointOnLine = (point: IPosition, lineStart: IPosition, lineEnd: IPosition): boolean => {
    const dxc = point.x - lineStart.x;
    const dyc = point.y - lineStart.y;
    const dxl = lineEnd.x - lineStart.x;
    const dyl = lineEnd.y - lineStart.y;

    // Проверка, лежит ли точка на прямой
    if (Math.abs(dxc * dyl - dyc * dxl) > 0.001) return false;

    // Проверка, лежит ли точка на отрезке
    if (Math.abs(dxl) >= Math.abs(dyl)) {
        return dxl > 0 ?
            lineStart.x <= point.x && point.x <= lineEnd.x :
            lineEnd.x <= point.x && point.x <= lineStart.x;
    } else {
        return dyl > 0 ?
            lineStart.y <= point.y && point.y <= lineEnd.y :
            lineEnd.y <= point.y && point.y <= lineStart.y;
    }
};

/**
 * Вычисляет расстояние между двумя точками.
 * 
 * @param p1 Первая точка
 * @param p2 Вторая точка
 * @returns Расстояние между точками
 */
export const distanceBetweenPoints = (p1: IPosition, p2: IPosition): number => {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
};


/**
 * Находит ближайшую точку пересечения линии с существующими стенами в мире Matter.js.
 * 
 * @param start - Начальная точка линии
 * @param end - Конечная точка линии
 * @param engine - Экземпляр движка Matter.js
 * @returns Ближайшая точка пересечения или null, если пересечений нет
 * 
 * @description
 * Эта функция перебирает все стены в мире Matter.js и проверяет,
 * пересекает ли заданная линия (от start до end) какую-либо из этих стен.
 * Если найдено несколько пересечений, возвращается ближайшая к начальной точке.
 * 
 * Функция полезна для:
 * - Определения, пересекает ли новая стена существующие
 * - Ограничения длины новой стены до точки пересечения с существующей стеной
 * - Предотвращения создания стен, проходящих сквозь другие стены
 * 
 * @example
 * const start = { x: 0, y: 0 };
 * const end = { x: 100, y: 100 };
 * const intersection = findIntersectionWithWalls(start, end, engine);
 * if (intersection) {
 *     console.log('Найдено пересечение:', intersection);
 * } else {
 *     console.log('Пересечений не найдено');
 * }
 */
export const findIntersectionWithWalls = (start: IPosition, end: IPosition, engine: Matter.Engine): IPosition | null => {
    const walls = Matter.Composite.allBodies(engine.world).filter(body => body.label === 'wall');
    let closestIntersection: IPosition | null = null;
    let minDistance = Infinity;

    walls.forEach(wall => {
        const vertices = wall.vertices;
        for (let i = 0; i < vertices.length; i++) {
            const nextIndex = (i + 1) % vertices.length;
            const intersection = lineIntersection(
                start, end,
                { x: vertices[i].x, y: vertices[i].y },
                { x: vertices[nextIndex].x, y: vertices[nextIndex].y }
            );
            if (intersection) {
                const distance = distanceBetweenPoints(start, intersection);
                if (distance < minDistance) {
                    minDistance = distance;
                    closestIntersection = intersection;
                }
            }
        }
    });

    return closestIntersection;
};