import { useEffect, useRef } from "react";
import Matter from "matter-js";


export const useEngine = () => {
    const engineRef = useRef<Matter.Engine | null>(null);

    useEffect(() => {
        const engine = Matter.Engine.create();
        engine.gravity.y = 1
        engineRef.current = engine;

        const runner = Matter.Runner.create();
        Matter.Runner.run(runner, engine);

        return () => {
            Matter.World.clear(engine.world, false);
            Matter.Engine.clear(engine);
            Matter.Runner.stop(runner);
        };
    }, []);

    return engineRef;
};