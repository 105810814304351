import { ReactNode, useState } from 'react';
import { Dialog, Group, Button, TextInput, Text } from '@mantine/core';
import { useStore } from '../stores/EditorStore';
import { NavbarLink } from './NavbarLink';
import { ArrowNarrowRight, ArrowRight, Click, Edit, Eye, GitFork, GitPullRequest, Help, Icon, LayoutAlignMiddle, Multiplier2x, Trash, Vector, ZoomIn } from 'tabler-icons-react';
import { Tool } from '../editor/editor/constants';
import { Image } from '@mantine/core';
import helpAddWall from '../res/add-wall.gif'
import helpDelete from '../res/delete.gif'
import helpEditFurniture from '../res/edit-furniture.gif'
import helpEditWall from '../res/edit-walls.gif'
import helpAddWindow from '../res/add-window.gif'
import helpAddDoor from '../res/add-door.gif'
import helpMeasure from '../res/measure-tool.gif'
interface IHelpBody {
  title: string,
  body: ReactNode
}




export function HelpDialog() {
  const [opened, setOpened] = useState(false);

  const { activeTool } = useStore();
  let helpBody: IHelpBody[] = [];

  helpBody[Tool.View] = {
    title: "Режим просмотра",
    body: <>
      <Group>
        <Click /> <p>Щелкните правой кнопкой мыши и перетащите, чтобы перемещаться по карте. </p>
      </Group>
      <Group>
        <ZoomIn /> <p>Используйте колесо прокрутки для увеличения или уменьшения масштаба</p>
      </Group>
    </>
  }

  helpBody[Tool.Remove] = {
    title: "Режим стирания",
    body: <>
      <Image src={helpDelete}></Image>
      <Group spacing="xs">
        <Click /> <ArrowNarrowRight /> <Trash /> <p> Нажмите на объект, чтобы удалить его из плана</p>
      </Group>
      <Group noWrap={true}>
        <Vector /> <p>Узлы стены можно удалять только в том случае, если они отсоединены.</p>
      </Group>
    </>
  }
  helpBody[Tool.Edit] = {
    title: "Режим редактирования",
    body: <>
      <Image src={helpEditFurniture}></Image>
      <Group spacing="xs">
        <Click /> <ArrowNarrowRight /> <Edit /> <p>Нажмите на мебель, чтобы включить элементы управления редактированием.</p>
      </Group>
      <Image src={helpEditWall}></Image>
      <Group noWrap={true}>
        <Vector /> <p>Нажмите и перетащите узлы стены, чтобы редактировать стены.</p>
      </Group>
    </>
  }
  helpBody[Tool.WallAdd] = {
    title: "Добавить стену",
    body: <>
      <Image src={helpAddWall}></Image>
      <Group noWrap={true}>
        <Click /> <p>Нажмите, чтобы добавить стену</p>
      </Group>
      <Group noWrap={true}>
        <Multiplier2x /> <p>Нажмите дважды, чтобы перестать расставлять стены.</p>
      </Group>
      <Group noWrap={true}>
        <GitFork /> <p>Нажмите на существующие стены, чтобы соединить их.</p>
      </Group>
    </>
  }

  helpBody[Tool.FurnitureAddWindow] = {
    title: "Добавить окно",
    body: <>
      <Image src={helpAddWindow}></Image>
      <Group noWrap={true}>
        <Click /> <p>Нажмите на стену, чтобы добавить окно</p>
      </Group>
    </>
  }
  helpBody[Tool.FurnitureAddDoor] = {
    title: "Добавить дверь",
    body: <>
      <Image src={helpAddDoor}></Image>
      <Group noWrap={true}>
        <Click /> <p>Нажмите на стену, чтобы добавить дверь</p>
      </Group>
      <Group noWrap={true}>
        <LayoutAlignMiddle /> <p>Щелкните средней кнопкой мыши, чтобы изменить положение двери.</p>
      </Group>
    </>
  }
    helpBody[Tool.Measure] = {
    title: "Линейка",
    body: <>
      <Image src={helpMeasure}></Image>
      <Group noWrap={true}>
        <Click /> <p>Нажмите и перетащите, чтобы измерить расстояние</p>
      </Group>
    </>
  }
  return (
    <>
        <NavbarLink onClick={() => setOpened((o) => !o)} icon={Help} label="Помощь" />

      <Dialog
        opened={opened}
        withCloseButton
        onClose={() => setOpened(false)}
        size="lg"
        radius="md"
        position={{ top: 130, right: 20 }}
      >
        <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
          <b>{helpBody[activeTool]?.title}</b>
          {helpBody[activeTool]?.body}
        </Text>
      </Dialog>
    </>
  );
}